var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0 d-flex",attrs:{"cols":"2"}},[_c('h3',[_vm._v("Resumen")])])],1),_c('v-row',{staticClass:"py-0 d-flex"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Desde","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaSeleccionadaDesde),callback:function ($$v) {_vm.fechaSeleccionadaDesde=$$v},expression:"fechaSeleccionadaDesde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}},[_c('v-date-picker',{attrs:{"max":_vm.fechaHoy},on:{"input":function($event){_vm.fechaDesde = false}},model:{value:(_vm.fechaSeleccionadaDesde),callback:function ($$v) {_vm.fechaSeleccionadaDesde=$$v},expression:"fechaSeleccionadaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hasta","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaSeleccionadaHasta),callback:function ($$v) {_vm.fechaSeleccionadaHasta=$$v},expression:"fechaSeleccionadaHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}},[_c('v-date-picker',{attrs:{"max":_vm.fechaHoy},on:{"input":function($event){_vm.fechaHasta = false}},model:{value:(_vm.fechaSeleccionadaHasta),callback:function ($$v) {_vm.fechaSeleccionadaHasta=$$v},expression:"fechaSeleccionadaHasta"}})],1)],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"info","elevation":"2"},on:{"click":_vm.buscarPorRangoFecha}},[_vm._v("Buscar")])],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar en tabla","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Zona")]),_c('th',{staticClass:"text-left"},[_vm._v("Ruta")]),_c('th',{staticClass:"text-left"},[_vm._v("Cantidad")])])]),_c('tbody',_vm._l((_vm.resumen),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item)+" ")])])}),0)]},proxy:true}])})],1),_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by":['ruta'],"sort-desc":[true],"headers":_vm.headers,"items":_vm.casos,"items-per-page":25,"search":_vm.search,"footer-props":{
          itemsPerPageOptions: [25, 50, 100, -1],
        },"loading":_vm.cargandoTabla,"loading-text":"Cargando datos...espere un momento","dense":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }