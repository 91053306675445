<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2" class="py-0 d-flex">
        <h3>Resumen</h3>
      </v-col>
    </v-row>
    <v-row class="py-0 d-flex">
      <v-col class="py-0" cols="2">
        <v-menu
          v-model="fechaDesde"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Desde"
              v-model="fechaSeleccionadaDesde"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaSeleccionadaDesde"
            @input="fechaDesde = false"
            :max="fechaHoy"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- <v-spacer></v-spacer> -->
      <v-col class="py-0" cols="2">
        <v-menu
          v-model="fechaHasta"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Hasta"
              v-model="fechaSeleccionadaHasta"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaSeleccionadaHasta"
            @input="fechaHasta = false"
            :max="fechaHoy"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-btn color="info" elevation="2" @click="buscarPorRangoFecha"
          >Buscar</v-btn
        >
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6" class="d-flex align-center justify-center">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar en tabla"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        ><v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Zona</th>
                <th class="text-left">Ruta</th>
                <th class="text-left">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in resumen" :key="index">
                <td>
                  {{ item }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table></v-col
      >
      <v-col
        ><v-data-table
          :sort-by="['ruta']"
          :sort-desc="[true]"
          :headers="headers"
          :items="casos"
          :items-per-page="25"
          :search="search"
          class="elevation-1"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100, -1],
          }"
          :loading="cargandoTabla"
          loading-text="Cargando datos...espere un momento"
          dense
        >
          <!-- <template v-slot:[`item.trackin_link`]="{ item }">
            <a :href="item.trackin_link" target="_blank">
              <v-btn x-small elevation="2" color="#1CACFC">Ver</v-btn>
            </a>
          </template> -->
        </v-data-table></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      casos: [],
      zonas: null,
      rutas: null,
      resumen: [],
      search: "",
      cargandoTabla: false,
      fechaDesde: false,
      fechaHasta: false,
      fechaSeleccionadaDesde: "",
      fechaSeleccionadaHasta: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      fechaHoy: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      headers: [
        {
          text: "Order id",
          value: "order_id",
          align: "start",
        },
        { text: "Ruta", value: "zona" },
        // { text: "Zona", value: "zonaName" },
      ],
    };
  },
  methods: {
    cargaInicial() {
      function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result.toISOString().substr(0, 10);
      }

      this.fechaSeleccionadaDesde = addDays(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
        7
      );
    },
    async buscarPorRangoFecha() {
      try {
        this.cargandoTabla = true;
        this.casos = [];
        // console.log(this.fechaSeleccionadaDesde);
        // console.log(this.fechaSeleccionadaHasta);

        if (this.fechaSeleccionadaDesde > this.fechaSeleccionadaHasta) {
          alert("Recuerda que la fecha inicial debe ser inferior a la final!");
        } else {
          const fechas = {
            fechaini: this.fechaSeleccionadaDesde,
            fechafin: this.fechaSeleccionadaHasta,
          };

          console.log("Enviando request...");
          const respuesta = await axios.post(
            "https://data.mongodb-api.com/app/triggers_realmapp-dqdra/endpoint/task_zone_detail",
            fechas
          );

          console.log(respuesta.status);
          this.casos = respuesta.data;
        }

        this.cargandoTabla = false;
        this.ordenarTablaResumen();
      } catch (error) {
        console.log(error);
        this.cargandoTabla = false;
      }
    },
    ordenarTablaResumen() {
      let total = this.casos.length;

      let zonas = this.casos.map((filtro) => {
        return filtro.zonaName;
      });
      let rutas = this.casos.map((filtro) => {
        return filtro.zona;
      });

      this.zonas = [...new Set(zonas)];
      this.rutas = [...new Set(rutas)];

      this.resumen.push(this.zonas, this.rutas);
      // this.resumen.push(this.rutas);

      console.log("TOTAL: ", total);
      console.log(this.resumen);
      // console.log(this.rutas);
    },
    async descargarHojaDeCalculo() {
      try {
        this.cargandoHojaDeCalculo = true;
        console.log("Generando archivo...");

        if (this.fechaSeleccionadaDesde > this.fechaSeleccionadaHasta) {
          alert("Recuerda que la fecha inicial debe ser inferior a la final!");
        } else {
          const fechas = {
            fechaini: this.fechaSeleccionadaDesde,
            fechafin: this.fechaSeleccionadaHasta,
          };

          console.log("Enviando request CSV...");
          const respuesta = await axios.post(
            "https://us-central1-rayo-dev.cloudfunctions.net/Dataclaro_Api/claroReporte",
            fechas
          );

          if (respuesta.status === 200) {
            console.log(respuesta.status);
            console.log("Descargando data...");
          }

          let csvFileName = `Reporte_${this.fechaSeleccionadaDesde}_${this.fechaSeleccionadaHasta}.csv`;
          let csvData = "";
          csvData = respuesta.data;
          let csvElement = document.createElement("a");

          csvElement.href =
            "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvData);
          csvElement.download = csvFileName;
          csvElement.click();
        }
        this.cargandoHojaDeCalculo = false;
      } catch (error) {
        console.log(error);
        this.cargandoHojaDeCalculo = false;
      }
    },
  },
  mounted() {
    this.cargaInicial();
    this.buscarPorRangoFecha();
    //this.crearEtiquetas();
  },
};
</script>
<style scoped>
a:link {
  text-decoration: none;
}
</style>
